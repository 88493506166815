<template>
  <main
    class="register-reseller blank-aside-js"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <p class="main-title weight-700">
      {{ $t('Business details') }}
    </p>

    <section class="filter-section-space">
      <DRFilter @changeBusiness="changeBusiness" ref="drFilter_comp" class="filter-section-comp"></DRFilter>
    </section>

    <section class="reseller-info register-reseller__reseller-info">
      <form
        @submit.prevent="preventFormDefault"
        class="reseller-info__container"
      >
        <div class="reseller-info__wrapper">
          <div class="reseller-info__company reseller-info__case">
            <label class="label reseller-info__label"
              >* {{ $t('Business name') }}</label
            >
            <input
              class="input"
              type="text"
              v-model="selectedBusiness_data.name"
              :readonly="!canBeEdited"
            />
          </div>

          <div class="reseller-info__dealer reseller-info__case">
            <label class="label reseller-info__label"
              >{{ $t('Authorized Dealer / H.P.') }} *</label
            >
            <input
              class="input"
              type="text"
              v-model="selectedBusiness_data.business_license_number"
              :readonly="!canBeEdited"
              :disabled="!canBeEdited"
            />
          </div>

          <div class="reseller-info__type reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('Agent Responsible')
            }}</label>
            <vue-select
              :options="agent_id.options"
              class="input cus-select"
              v-model="agent_id.selected"
              :readonly="!canBeEdited"
              :disabled="!canBeEdited || isDistributorAgent"
            ></vue-select>
          </div>

          <div class="reseller-info__phone-reseller reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('Business phone')
            }}</label>
            <input
              class="input"
              type="tel"
              v-model="selectedBusiness_data.phone_number"
              :readonly="!canBeEdited"
              :disabled="!canBeEdited"
            />
          </div>

          <div class="reseller-info__phone-contact reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('Mobile phone contact')
            }}</label>
            <input
              class="input"
              type="tel"
              v-model="selectedBusiness_data.contact_phone_number"
              :readonly="!canBeEdited"
              :disabled="!canBeEdited"
            />
          </div>

          <div class="d-flex justify-content-between align-items-center flex-wrap reseller-info__case reseller-info__checkboxes">
            <div class="d-flex align-items-center">
              <v-checkbox
                color="#0D3856"
                class="cus-checkbox"
                v-model="selectedBusiness_data.section_20"
                :readonly="!canBeEdited"
                :disabled="!canBeEdited"
              ></v-checkbox>
              <label class="px-5 fs16 color-primary">{{
                $t('Article {num}', { num: '20' })
              }}</label>
            </div>
          </div>
          <div class="container" id="app-container" v-if="checked">
            <form @submit.prevent="handleSubmit" class="ravkav details form">
              <div class="activity_hours">
                <label class="activity_hours__label" for="hours">{{
                  $t("Select operating hours")
                }}</label>
                <input
                  id="closing_hours"
                  v-model="closing_hour"
                  value=""
                  type="text"
                />
                -
                <input
                  id="opening_hours"
                  v-model="opening_hour"
                  value=""
                  type="text"
                />
              </div>
              <div class="comments">
                <label class="comments__label" for="text">{{
                  $t("Remarks")
                }}</label>
                <input
                  id="comment"
                  v-model="comment"
                  value=""
                  class="input comment"
                  type="text"
                />
              </div>
            </form>
          </div>
        </div>

        <div class="reseller-info__wrapper">
          <!-- <div class="reseller-info__adress reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('Address')
            }}</label>
            <vue-google-autocomplete
              id="map"
              classname="input"
              placeholder="הזן כתובת מלאה"
              v-on:placechanged="getAddressData"
              v-model="googleAddressInput"
              :readonly="!canBeEdited"
              :disabled="!canBeEdited"
            >
            </vue-google-autocomplete>
          </div> -->
          <div class="reseller-info__adress reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('Country')
            }}</label>
            <vue-select
              :options="countryList"
              class="input cus-select"
              v-model="selectedBusiness_data.country"
              :readonly="!canBeEdited"
              :disabled="!canBeEdited"
            ></vue-select>
            <!-- <input class="input" type="text" v-model="selectedBusiness_data.country"/> -->
          </div>

          <div class="reseller-info__adress reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('City')
            }}</label>
            <input class="input" type="text" v-model="selectedBusiness_data.city"/>
          </div>

          <div class="reseller-info__adress reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('Address')
            }}</label>
            <input
              class="input"
              type="text"
              v-model="selectedBusiness_data.street"
            />
          </div>

          <div class="reseller-info__post reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('Postal Code')
            }}</label>
            <input
              class="input"
              type="text"
              v-model="selectedBusiness_data.zip_code"
              :readonly="!canBeEdited"
              :disabled="!canBeEdited"
            />
          </div>

          <div class="reseller-info__adress reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('Client No.')
            }}</label>
            <input
              class="input"
              type="text"
              v-model="selectedBusiness_data.business_identifier"
              :readonly="!canBeEdited"
              :disabled="!canBeEdited"
            />
          </div>

          <div class="reseller-info__work reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('Activity time')
            }}</label>

            <div class="reseller-info__work-wrapper">
              <div class="reseller-info__work-days">
                <div class="reseller-info__sunday">
                  <label class="label reseller-info__sunday-label">{{
                    $t('Sundays-Thursdays')
                  }}</label>
                  <div class="reseller-info__sunday-wrapper">
                    <input
                        :readonly="!canBeEdited"
                        :disabled="!canBeEdited" class="input reseller-info__input" type="text" />
                    <span class="reseller-info__dash">-</span>
                    <input
                        :readonly="!canBeEdited"
                        :disabled="!canBeEdited" class="input reseller-info__input" type="text" />
                  </div>
                </div>

                <div class="reseller-info__friday">
                  <label class="label reseller-info__friday-label">{{
                    $t('Friday and holiday eves')
                  }}</label>
                  <div class="reseller-info__friday-wrapper">
                    <input
                        :readonly="!canBeEdited"
                        :disabled="!canBeEdited" class="input reseller-info__input" type="text" />
                    <span class="reseller-info__dash">-</span>
                    <input
                        :readonly="!canBeEdited"
                        :disabled="!canBeEdited" class="input reseller-info__input" type="text" />
                  </div>
                </div>

                <div class="reseller-info__saturday">
                  <label class="label reseller-info__saturday-label" for>{{
                    $t('Saturdays and holidays')
                  }}</label>
                  <div class="reseller-info__saturday-wrapper">
                    <input
                        :readonly="!canBeEdited"
                        :disabled="!canBeEdited" class="input reseller-info__input" type="text" />
                    <span class="reseller-info__dash">-</span>
                    <input
                        :readonly="!canBeEdited"
                        :disabled="!canBeEdited" class="input reseller-info__input" type="text" />
                  </div>
                </div>
              </div>

              <div class="reseller-info__everyday">
                <input
                  class="reseller-info__checkbox"
                  type="checkbox"
                  id="everyday"
                  :readonly="!canBeEdited"
                  :disabled="!canBeEdited"
                />
                <label
                  class="label reseller-info__checkbox-label"
                  for="everyday"
                  >24X7</label
                >
              </div>
            </div>
          </div>
        </div>

        <div class="reseller-info__wrapper">
          <div class="reseller-info__email reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('E-mail')
            }}</label>
            <input
              class="input"
              type="email"
              v-model="selectedBusiness_data.contact_mail"
              :readonly="!canBeEdited"
              :disabled="!canBeEdited"
            />
          </div>

          <div class="reseller-info__email-2 reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('Email Accounting')
            }}</label>
            <input
              class="input"
              type="email"
              v-model="selectedBusiness_data.contact_account_mail"
              :readonly="!canBeEdited"
              :disabled="!canBeEdited"
            />
          </div>

          <div class="reseller-info__profile reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('Supplier profile')
            }}</label>
            <vue-select
              :options="supplier.options"
              class="input cus-select"
              v-model="supplier.selected"
              :readonly="!canBeEdited"
              :disabled="!canBeEdited"
            ></vue-select>
          </div>

          <div class="reseller-info__percent reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('Profit Percentage Profile')
            }}</label>
            <vue-select
              :options="profit.options"
              class="input cus-select"
              v-model="profit.selected"
              :readonly="!canBeEdited"
              :disabled="!canBeEdited"
            ></vue-select>
          </div>

          <div class="reseller-info__commission reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('Fees profile')
            }}</label>
            <vue-select
              :options="commission.options"
              class="input cus-select"
              v-model="commission.selected"
              :readonly="!canBeEdited"
              :disabled="!canBeEdited"
            ></vue-select>
          </div>

          <div class="reseller-info__percent-tourism reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('Tourism Percentage Profile')
            }}</label>
            <vue-select
              :options="tourismProfile"
              class="input cus-select"
              v-model="tourism_profile.selected"
              label="tour_name"
              :readonly="!canBeEdited"
              :disabled="!canBeEdited"
            ></vue-select>
          </div>
        </div>
      </form>
      <div class="mt-5 mb-10 tag-section reseller-info__container d-block">
        <label>{{ $t('Tags') }}</label>
        <div class="tag-block">
          <div v-for="(tag, index) in tags" :key="index">
            <div
              class="tag-item tag-item-active"
              v-if="selectedBusiness_data.tags && selectedBusiness_data.tags.includes(tag.id)"
            >
              {{ tag.name }}
              <div v-if="canBeEdited" class="tag-close_btn" @click="removeTag(tag.id)">
                <img src="/assets/img/icons/times-light.svg" alt="" />
              </div>
            </div>
            <div
              class="tag-item pointer"
              @click="addTag(tag.id)"
              v-else-if="canBeEdited"
            >
              {{ tag.name }}
            </div>
            <div
              class="tag-item"
              v-else
            >
              {{ tag.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-action reseller-info__container d-block">
        <div class="d-flex justify-content-between">
          <div class="d-flex btn-group">
            <button v-if="canBeEdited" class="primary-btn" @click="update">
              {{ $t('Save') }}
            </button>
            <button :disabled="!distributeID && !(isAdmin || isPtAdmin)" class="primary-btn bg-yellow" @click="openNewBusinessModal">
              {{ $t('New') }}
            </button>
          </div>
          <div class="d-flex btn-group">
            <button v-if="canBeEdited" class="primary-btn bg-green3" @click="triggerFreezeBusinessHandler">
              {{ selectedBusiness_data.frozen ? $t('Unfreeze') : $t('Freeze') }}
            </button>
            <button v-if="canBeEdited" class="primary-btn bg-red1" @click="triggerLockBusinessHandler">
              {{ selectedBusiness_data.locked ? $t('Unlock') : $t('Lock') }}
            </button>
          </div>
        </div>
        <div class="d-flex justify-content-between last-btn">
          <div class="d-flex btn-group">
            <button v-if="canBeEdited" class="primary-btn bg-red1" @click="showDeleteBusinessModal">
              {{ $t('Delete') }}
            </button>
            <router-link
              v-if="isPtAdmin"
              :to="{ name: 'CustomerLists' }"
              tag="a"
              class="primary-btn bg-yellow color-white"
            >
              {{ $t('Customer Search') }}
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <NewBusinessModal 
      :distributor_id="distributeID" 
      :distribute_section_20="section_20"
      @setNewseller="setNewseller"
    ></NewBusinessModal>
    <ConfirmModal name="confirmBusinessDelete" @confirmData="deleteBusinessHandler">
      <template v-slot:modal-content>
        {{ $t('Do you want to delete this business ({business})?', {business: selectedBusiness_data.name}) }}
      </template>
    </ConfirmModal>

    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import DRFilter from "../../../components/management/DRFilter.vue";
import NewBusinessModal from "../../../components/management/NewBusinessModal.vue";
import InfiniteScrollSelect from "../../../components/InfiniteScrollSelect.vue";
import ConfirmModal from "@/components/management/ConfirmModal.vue";
import user_mixin from '@/utility/user_mixin';
import { CountriesConst } from "@/common/constants.js";

export default {
  name: 'DRBusinessDetails',
  components: {
    VueGoogleAutocomplete,
    DRFilter,
    NewBusinessModal,
    InfiniteScrollSelect,
    ConfirmModal
  },
  mixins: [user_mixin],
  data() {
    return {
      countryList: CountriesConst,
      checked: false,
      opening_hour: '',
      closing_hour: '',
      comment: '',
      businessID: "",
      distributeID: "",
      section_20: "",
      commission: {
        options: [{ 
          label: '', 
          code: null
        }],
        selected: { 
          label: '', 
          code: null
        }
      },
      profit: {
        options: [{ 
          label: '', 
          code: null
        }],
        selected: { 
          label: '', 
          code: null
        }
      },
      supplier: {
        options: [{ 
          label: '', 
          code: null
        }],
        selected: { 
          label: '', 
          code: null
        }
      },
      selectedBusiness_data: {},
      googleAddressInput: '',
      tourism_profile:{
        selected: {}
      },
      agent_id: {
        options: [],
        selected: '',
      },
      test: {
        options: [],
        selected: '',
      },
    };
  },
  computed: {
    ...mapState({
      tags: (state) => state.seller_tourism_percentage.tags,
      businessProfileData: (state) =>
        state.seller_tourism_percentage.businessProfileData,
      businessData: (state) => state.seller_tourism_percentage.businessData,
      sellerLoading: (state) => state.seller_tourism_percentage.loading,
      tourismProfile: (state) => state.category_products.tourismProfile,
      agentsResponsible: (state) => state.seller_tourism_percentage.agentsResponsible,
    }),
    loading() {
      return this.sellerLoading
    },
    canBeEdited() {
      return !!(this.selectedBusiness_data && this.selectedBusiness_data.can_be_edited);
    }
  },
  async created() {
    if (this.agentsResponsible.length == 0) {
      await this.getAgentsResponsible();
    }
    if (this.agentsResponsible.length != 0) {
      this.agentsResponsible.forEach(el => {
        if (!this.isDistributorAgent || this.userId == el.id) {
          this.agent_id.options.push({
            label: el.name,
            code: el.id
          });
        }
      });
    }
    if (this.tags.length == 0) {
      await this.getTags();
    }
    if (this.businessProfileData.length == 0) {
      await this.getBusinessProfile();
    }
    if (this.businessProfileData.length != 0) {
      this.businessProfileData.commission.forEach((el) => {
        this.commission.options.push({ label: el.name, code: el.id });
      });
      this.commission.selected = this.commission.options[0];

      this.businessProfileData.profit.forEach((el) => {
        this.profit.options.push({ label: el.name, code: el.id });
      });
      this.profit.selected = this.profit.options[0];

      this.businessProfileData.supplier.forEach((el) => { 
        this.supplier.options.push({ label: el.name, code: el.id });
      });
      this.supplier.selected = this.supplier.options[0];
    }
    if (this.tourismProfile.length == 0) {
      await this.getTourismProfile();
    }
    this.resetBusinessData();
  },
  methods: {
    ...mapActions('seller_tourism_percentage', {
      getTags: 'getTags',
      getBusinessProfile: 'getBusinessProfile',
      updateBusinessData: 'updateBusinessData',
      newRavkavStation: 'newRavkavStation',
      getBusiness: 'getBusiness',
      getAgentsResponsible: 'getAgentsResponsible',
      deleteBusiness: 'deleteBusiness',
      freezeBusiness: 'freezeBusiness',
      lockBusiness: 'lockBusiness',
      activateBusiness: 'activateBusiness',
    }),
    ...mapActions("category_products", {
      getTourismProfile: "getTourismProfile"
    }),
    async showDeleteBusinessModal() {
      this.$modal.show('confirmBusinessDelete');
    },
    async deleteBusinessHandler() {
      // delete selected business
      const id = this.businessID;
      await this.deleteBusiness({id: id});
      this.$refs.drFilter_comp.updateBusinessOption();
    },
    async triggerFreezeBusinessHandler() {
      const id = this.businessID;
      if (this.selectedBusiness_data.frozen) {
        await this.activateBusiness({id: id});
      } else {
        await this.freezeBusiness({id: id});
      }
      this.$refs.drFilter_comp.updateBusinessOption();
    },
    async triggerLockBusinessHandler() {
      const id = this.businessID;
      if (this.selectedBusiness_data.locked) {
        await this.activateBusiness({id: id});
      } else {
        await this.lockBusiness({id: id});
      }
      this.$refs.drFilter_comp.updateBusinessOption();
    },
    preventFormDefault(e) {
      e.preventDefault();
    },
    changeBusiness(data) {
      this.businessID = data.business_id;
      this.distributeID = data.distribute_id;
      this.section_20 = data.section_20;
      let selectedBusinessData = {...this.businessData.find(function (el) {
        return el.id == data.business_id;
      })};
      this.selectedBusiness_data = {...selectedBusinessData};

      this.selectedBusiness_data.country = this.countryList.find(el => {
        return el.code == this.selectedBusiness_data.country;
      });

      const fullAddress = `${this.selectedBusiness_data.street ?? ''} ${
        this.selectedBusiness_data.number ?? ''
      }, ${this.selectedBusiness_data.city ?? ''}, ${
        this.selectedBusiness_data.country ?? ''
      }`;
      if (/[^\s,]/.test(fullAddress)) {
        this.googleAddressInput = fullAddress;
      }

      this.agent_id.selected = this.agent_id.options.find(el => {
        return el.code == selectedBusinessData.agent_id;
      })
    },
    addTag(id) {
      this.selectedBusiness_data.tags.push(id);
    },
    removeTag(id) {
      this.removeA(this.selectedBusiness_data.tags, id);
    },
    async update() {
      const params = {
        name: this.selectedBusiness_data.name,
        business_license_number:
          this.selectedBusiness_data.business_license_number,
        agent_id: this.agent_id?.selected?.code,
        phone_number: this.selectedBusiness_data.phone_number,
        second_phone_number: this.selectedBusiness_data.contact_phone_number,
        opening_hours: this.selectedBusiness_data.opening_hours,
        contact_mail: this.selectedBusiness_data.contact_mail,
        contact_account_mail: this.selectedBusiness_data.contact_account_mail,
        suppliers_profile_id: this.supplier.selected.code,
        profitPercentagesProfile_id: this.profit.selected.code,
        commissionsProfile_id: this.commission.selected.code,
        section_20: this.selectedBusiness_data.section_20,
        tags: this.selectedBusiness_data.tags,

        country: this.selectedBusiness_data.country.code,
        city: this.selectedBusiness_data.city,
        street: this.selectedBusiness_data.street,
        number: this.selectedBusiness_data.number,
        latitude: this.selectedBusiness_data.latitude,
        longitude: this.selectedBusiness_data.longitude,
        zip_code: this.selectedBusiness_data.zip_code,
        business_identifier: this.selectedBusiness_data.business_identifier,
      };
      const id = this.businessID;
      await this.updateBusinessData({
        id: id,
        params: params,
      });
      if (this.checked) {
        this.newRavkavStation({
          id: id,
          city: params.city,
          address: params.street,
          opening_hour: this.opening_hour,
          closing_hour: this.closing_hour,
          comment: this.comment,
        });
      }
      this.$refs.drFilter_comp.updateBusinessOption();
    },
    getAddressData(address) {
      this.selectedBusiness_data.country = address.country;
      this.selectedBusiness_data.city = address.locality;
      this.selectedBusiness_data.street = address.route;
      this.selectedBusiness_data.number = address.street_number;
      this.selectedBusiness_data.latitude = address.latitude;
      this.selectedBusiness_data.longitude = address.longitude;
    },
    openNewBusinessModal() {
      this.$modal.show('newBusinessModal');
    },
    setNewseller(id) {
      this.$refs.drFilter_comp.updateBusineOptionAfterCreateReseller(id);
    },
    async resetBusinessData() {
      if (this.selectedBusiness_data) {
        this.changeBusiness({
          business_id: this.businessID,
          distribute_id: this.distributeID,
          section_20: this.section_20,
        });
      }
    }
  },
};
</script>
<style lang="scss">
.cus-select input::placeholder {
  opacity: 0.4;
}
</style>
<style lang="scss" scoped>
.reseller-info__work-wrapper {
  height: 238.42px;
}
.reseller-info__checkboxes {
  height: calc(61.2727px * 2 - 20px);
}
.reseller-info {
  padding: 0 55px 100px;
  @media (max-width: 1365px) {
    padding: 0 0 50px;
  }
  &:before {
    display: none;
  }
}
.reseller-info__label {
  margin-bottom: 0;
}
.filter-section-space {
  padding: 0 45px;
  .filter-section-comp {
    margin: 0 auto;
    max-width: 1500px;
  }
  @media (max-width: 1365px) {
    padding: 0 15px;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
}

.sheniv-tax {
  .input {
    max-width: 180px;
    height: 36px;
  }
}

.bottom-action {
  .btn-group {
    width: 340px;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
  }
  .primary-btn {
    width: 160px;
    height: 40px;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .last-btn {
    justify-content: flex-end;
  }
}
#opening_hours {
  width: 40px;
  border-style: solid;
  border-width: 1px;
  background-color: #ffffff;
}
#closing_hours {
  width: 40px;
  border-style: solid;
  border-width: 1px;
  background-color: #ffffff;
}
@media screen and (max-width: 719px) {
  .bottom-action {
    .btn-group {
      justify-content: center;
    }
    .last-btn {
      justify-content: center;
    }
  }
}

.ltr-type {
  .reseller-info__checkbox-label::after {
    right: unset;
    left: -27px;
  }
  .reseller-info__checkbox-label::before {
    right: unset;
    left: -30px;
  }
}
</style>