<template>
    <vue-select
        :options="items"
        :filterable="false"
        v-model="selectedValue"

        :label="labelField"
        :getOptionKey="option => keyField && option ? option[keyField] : option"

        @open="onOpen"
        @close="onClose"
        @search="searchItems"
    >
        <template #list-footer>
            <li ref="load" class="loader" v-show="hasNextPage">Loading more options...</li>
        </template>
    </vue-select>
</template>

<script>
export default {
    name: 'InfiniteScrollSelect',
    props: {
        value: {
            type: String
        },
        labelField: {
            type: String,
            default: null
        },
        keyField: {
            type: String,
            default: null
        },
        returnObject: {
            type: Boolean,
            default: true
        },
        itemsPerPage: {
            type: Number,
            default: 50
        },
        fetchDataFunction: {
            type: Function
        },
        additionalSearchParams: {
            type: Object
        }
    },
    data: () => ({
        selectedValue: null,
        observer: null,
        page: 0,
        totalItems: 0,
        search: '',
        items: []
    }),
    computed: {
        hasNextPage() {
            return this.items.length < this.totalItems;
        },
    },
    watch: {
        selectedValue(val) {
            let finalVal = null;
            if (val) {
                if (typeof val === 'object' && !this.returnObject) {
                    finalVal = val[this.keyField];
                } else {
                    finalVal = val;
                }
            } else {
                finalVal = null;
            }
            this.$emit('input', finalVal);
            this.search = null;
            this.loadItems(0);
        },
        value(val) {
            this.setSelectedValue(val);
        }
    },
    async mounted() {
        /**
         * You could do this directly in data(), but since these docs
         * are server side rendered, IntersectionObserver doesn't exist
         * in that environment, so we need to do it in mounted() instead.
         */
        this.setSelectedValue(this.value);
        this.loadItems(0);
        this.observer = new IntersectionObserver(this.infiniteScroll);
    },
    methods: {
        setSelectedValue(val) {
            let finalVal = null;
            if (val) {
                if (typeof val !== 'object' && this.labelField) {
                    finalVal = {};
                    finalVal[this.keyField] = val;
                    finalVal[this.labelField] = val;
                } else {
                    finalVal = val;
                }
            } else {
                finalVal = null;
            }
            this.selectedValue = finalVal;
            this.loadItems(0);
        },
        async onOpen() {
            if (this.hasNextPage) {
                await this.$nextTick();
                this.observer.observe(this.$refs.load);
            }
        },
        onClose() {
            this.search = null;
            this.observer.disconnect();
        },
        async infiniteScroll([{ isIntersecting, target }]) {
            if (isIntersecting) {
                const ul = target.offsetParent;
                const scrollTop = target.offsetParent.scrollTop;
                await this.$nextTick();
                await this.loadItems();
                ul.scrollTop = scrollTop;
            }
        },
        async loadItems(page) {
            page = page == null ? ++this.page : parseInt(page);
            this.page = page;

            if (!this.search && this.selectedValue) {
                this.search = typeof this.selectedValue === 'object' && this.labelField
                    ? this.selectedValue[this.labelField] : this.selectedValue;
            }

            let searchParams = {
                per_page: this.itemsPerPage,
                page: page,
                search: this.search,
                additionalSearchParams: this.additionalSearchParams
            };

            this.fetchDataFunction(searchParams).then(
                (result) => {
                    let loaded_items = result.data || result.items;
                    loaded_items = Array.isArray(loaded_items) ? loaded_items : [];
                    this.items = page === 0 ? loaded_items : this.items.concat(loaded_items);

                    let total_items = result.total || result.totalItems;
                    total_items = total_items || 0;
                    this.totalItems = total_items;
                },
                (error) => {
                    this.items = [];
                    this.totalItems = 0;
                }
            );
        },
        async searchItems(search, loading) {
            this.observer.disconnect();
            loading(true);
            this.search = search;
            this.loadItems(0).then(async () => {
                loading(false);

                if (this.hasNextPage) {
                    await this.$nextTick();
                    if (this.$refs.load) {
                        this.observer.observe(this.$refs.load);
                    }
                }
            });
        }
    },
}
</script>

<style scoped>
.loader {
    text-align: center;
    color: #bbbbbb;
}
</style>