<template>
  <div class="tag-block">
    <template v-for="(tag, index) in tags">
      <div
        class="tag-item tag-item-active"
        :key="index"
        v-if="selectedTags && selectedTags.includes(tag.id)"
      >
        {{ tag.name }}
        <div class="tag-close_btn" @click="removeTag(tag.id)">
          <img src="/assets/img/icons/times-light.svg" alt="" />
        </div>
      </div>
      <div
        class="tag-item pointer"
        :key="index"
        @click="addTag(tag.id)"
        v-else
      >
        {{ tag.name }}
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: 'TagsBlockThree',
  props: {
    selectedTags: Array
  },
  computed: {
    ...mapState({
      tags: (state) => state.seller_tourism_percentage.tags,
    }),
  },
  async created() {
    if (this.tags.length == 0) {
      await this.getTags();
    }
  },
  methods: {
    ...mapActions('seller_tourism_percentage', {
      getTags: 'getTags'
    }),
    addTag(id) {
      this.selectedTags.push(id);
    },
    removeTag(id) {
      this.removeA(this.selectedTags, id);
    },
  }

}
</script>

<style lang="scss" scoped>
</style>